@import url('https://fonts.googleapis.com/css2?family=Orbitron:wght@400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Caveat+Brush&display=swap');

html {
  scroll-behavior: smooth;
}

* {
  color: #f9f9f9;
  font-family: 'Orbitron', sans-serif;
  box-sizing: border-box;
}



body {
  background-color: #2e2a26;;
  color: #f9f9f9;
  font-family: 'Orbitron', sans-serif;
  font-weight:500;
  margin: 0;
  padding: 0;
}

a {
  color: #f9f9f9;
  text-decoration: none;
}

@media only screen and (min-width: 768px) {
  body {
    font-size: 16px;
  }
}
@media only screen and (min-width: 480px) and (max-width: 768px) {
  body {
    font-size: 15px;
  }
}
@media only screen and (max-width: 479px) {
  body {
      font-size: 14px;
  }
}